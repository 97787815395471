@use 'sass:map';
.resizable-container {
  height: calc(100vh - #{$navbar-height +2px} );
  max-height: calc(100vh - #{$navbar-height +2px});
     & > section[id="main-panel"] {
      width:100%;
      & >div[class="main-panel-content"]{
        height: 100%;
        overflow: auto;

      }
    }
    & > section[id="sub-panel"] {
      position: relative;
      background-color: $themebackground;
       & > div[id="dragbar"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        z-index: 100;
        &.hide{
          height: 0;
        }
        cursor: row-resize;
        background: $border-color;
        transition: background 0.3s ease-in-out 0s;
        &:hover{
            background: $widget;
            transition: background 0.3s ease-in-out 0.3s;
        }
      }
      & >div[class="sub-panel-content"]{
        height: 100%;
        overflow: auto;
        padding-top:  $spacer * 1.5; //mt-4 equivelant

      }
    }
    &> div[class="error"] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        height: 100%;
        width: 100%;
        color: $text-muted;
        background-color: $themebackground;
        padding-bottom: 5.5rem;
     & > i[class="mdi mdi-alert-circle-outline"]{
        font-size: 5.5rem   ;
      }
     
    }
    &>div[class="loader"]{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        height: 100%;
        width: 100%;
        color: $text-muted;
        padding-bottom: 5.5rem;
        background-color: $themebackground;
        > div {
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                border: 0.3rem solid rgba($widget, 0.3);
                border-top-color: $widget;
                animation: 1.5s spin infinite linear;
        }
    }
}

//--nav
.nav-container {
  border-bottom: $border-width solid $border-color;
  background-color: $themebackground;//$light-themebackground;
  transition: height $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  //overflow-y: hidden;
  white-space: nowrap;
  overflow: none;
  & >div {
    margin: 0;
    display: inline-block;
    text-align: center;
    justify-content: center;
    border-right: 1px solid $border-color;
    &> span{

        &.nav-link.active {
          background: $light-themebackground-active;
        }
    }
  }
  overflow-y: auto;
  overflow-x: none;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $border-color;
    box-shadow: inset 0 0 6px $border-color;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    box-shadow: inset 0 0 6px lighten($input-bg, 15%);
    -webkit-box-shadow: inset 0 0 6px lighten($input-bg, 15%);
  }
  &::-webkit-scrollbar-corner {
    background: $border-color;
  }
}


.container-content {
  margin-top:$container-margin;
}
.card-container {
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 4px;
  padding: $spacer;
  margin-bottom: $spacer;
  text-decoration: none;
  color: $body-color;
  transition-duration: 0.3s;
  transition-property: 'background';
  background: lighten($card-bg, 2%); 

  &.active {
    text-decoration: none;
    color: inherit;
    background: lighten($card-bg, 10%);
    border-color: map.get($theme-colors, 'info');
  }
  &.active-light{

    text-decoration: none;
    color: inherit;
    background: lighten($card-bg, 5%);
  }
  .fa-trash {
    color: $text-muted;
    &:hover {
      color: map.get($theme-colors, 'danger');
    }
  }
  .fa-check-circle-o {
    color: $text-muted;
    &:hover {
      color: map.get($theme-colors, 'success');
    }
  }
  &.light{
       background: lighten($card-bg, 5%); 
  }
}