.react-bootstrap-table {

  table {

    > tr th,
  table tr td {
    border: 2px solid;
  }

    td {
      position: relative;
      text-align: center;
      justify-content: center;
      display: center;
      color: $white;
    }
    th:after,
    th:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
    }
    th:before {
      top: 0;
      border-top: 2px solid $border-color;
    }
    th:after {
      bottom: 0;
      //border-bottom:3px solid $border-color;
    }
    tr:nth-child(even) {
      background-color: darken($light-themebackground, 1.5%);
    }
    tr:hover{
      .abs-left-icon-container>i{
        display: block;
      }
      .abs-right-icon-container>i{
        display: block;
      }
    }
    &.table {
      
      table-layout: auto;
      thead {
        th {
          z-index:3; 
          opacity: 1000;
          background-color: $light-themebackground;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          // border-top: 4px solid $border-color;
          padding: $table-cell-padding 2.3rem $table-cell-padding $table-cell-padding;
          &:focus {
            outline: none;
          }
          // .mdi,
          .order-4,
          .caret-4-asc,
          .caret-4-desc {
            position: absolute;
            right: 10px;
            //padding-left: 15px;
            cursor: pointer;
          }
          .filter-4{
            position: absolute;
            right: 10px;
            padding-left: 15px;
            opacity: 0.4;
            cursor: pointer; 
          }
          .filter-4-active{
            position: absolute;
            right: 10px;
            padding-left: 15px;
            cursor: pointer; 
          }
          .filter{
            opacity: 0.4;
            cursor: pointer; 
          }
          .filter-active{

            cursor: pointer; 
          }
          


        }
      }


      .btn {
        padding: 0.1rem 1rem;
        vertical-align: top;
        i {
          font-size: .875rem;
          margin-right: .3rem;
        }
      }
    }

    .abs-left-icon-container {
      position: absolute;
      left: 5%;
      top: calc(50% - 0.8em);
      &>i{
        display: none;
        padding: 0.3em;
        border-radius: 50px;
        &:hover{
          box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.048);
          border:none;
          opacity: 1;
        }
      }
    }
    .abs-right-icon-container {
      position: absolute;
      left: 5%;
      top: calc(50% - 0.8em);
    }
  }
  &.orders-table {
    thead {
      tr {
        background: theme-color(primary);
        th {
          border: 0;
          color: #fff;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.react-bootstrap-table-pagination {
  margin-top: 15px;
  align-items: flex-end;
  .react-bs-table-sizePerPage-dropdown {
    .dropdown-toggle {
      padding: 0.6rem 2.5rem;
      @extend .btn-primary;
    }
  }
  .react-bootstrap-table-pagination-list {
    .pagination {
      margin-bottom: 0;
      @include justify-content(flex-end);
    }
  }
}







.sparkline-bar
{
  //it should be in the center but we also have to adjust for the padding of the element
  top: calc(50% - 0.3em);//calc($table-cell-padding / 2); 
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  &.sparkline-bar-text{
    color:$white;
    z-index: 2;
    position: absolute;
    left: -1rem;
  }
  &.sparkline-bar-text-inverse{
    @extend .sparkline-bar-text
  }

  @each $color, $value in $theme-colors {
    &.bar-#{$color} {
      background-color: $value;
      padding: 0 0.3em;
      color: $white;
      max-width: 95%;
      height: 1em;
      position: absolute;
      z-index: 1;
      left: -$table-cell-padding;
      border-radius: 0 0.1em 0.1em 0;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-left: none;
    }
    &.bar-inverse-positive-#{$color} {
      @extend .sparkline-bar, .bar-#{$color};
      border-left: none;
      left: calc(50% - 1em) ;
      max-width: calc(50% - 0.5em);
    }
    &.bar-inverse-negative-#{$color} {
      @extend .sparkline-bar, .bar-#{$color};
      @extend .bar-inverse-positive-#{$color};
      transform: rotate(180deg) translate(calc(100%));
    }
  }
}

