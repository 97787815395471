.price-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.price-card {
  background-color: $light-card-bg;
  border-radius: 10px;
  max-width: 100%;
}

.price-card.current-plan {
  border: 1px solid #815fff;
  transform: scale(1.05);
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.feature-container {
  display: flex;
  align-self: flex-start;
  gap: 1rem;
  font-size: 1.2rem;
}

.price-btn,
.current-plan-btn {
  border: none;
  color: white;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 15px;
}
.price-btn {
  background-color: #815fff;
  transition: 0.1s ease-in-out;
  transform: scale(1);
}

.price-btn:hover,
.price-btn:focus {
  transform: scale(1.05);
  text-decoration: none;
  color: white;
}
.price-btn:active {
  transform: scale(1);
}

.current-plan-btn {
  background-color: lighten($card-bg-light, 5%);
  border: 1px solid $border-color;
  gap: 0.5rem;
}

.price-check {
  color: #815fff;
}
.current-plan-check {
  font-size: 1.4rem;
  color: #815fff;
}

@media (max-width: 992px) {
  .price-cards {
    flex-direction: column;
  }
}
