@import 'intro.js/introjs.css';

.introjs-tooltip {
  background-color: $intro-tooltip-color;
  color: $intro-button-color;
}

.introjs-button,
.introjs-button:hover,
.introjs-button:focus,
.introjs-button:active,
.introjs-disabled,
.introjs-disabled:focus,
.introjs-disabled:hover {
  outline: none;
  background-image: none;
  background-color: transparent;
  color: $intro-button-color;
  border: 1px solid transparent;
  border-radius: 50px;
  box-shadow: none;
  text-shadow: none;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $intro-button-color;
  }
}

.introjs-disabled,
.introjs-disabled:focus,
.introjs-disabled:hover {
  color: $intro-disabled-color;
  border: 1px solid transparent;
}

.introjs-arrow {
  border: $intro-arrow-border;

  &.top,
  &.top-middle,
  &.top-right {
    border-color: transparent transparent $intro-tooltip-color;
    top: -20px;
    left: 20px;
  }

  &.bottom,
  &.bottom-middle,
  &.bottom-right {
    border-color: $intro-tooltip-color transparent transparent;
    bottom: -20px;
    left: 20px;
  }

  &.left,
  &.right {
    top: 20px;
  }

  &.left-bottom,
  &.right-bottom {
    bottom: 20px;
  }

  &.left,
  &.left-bottom {
    left: -20px;
    border-color: transparent $intro-tooltip-color transparent transparent;
  }

  &.right,
  &.right-bottom {
    right: -20px;
    border-color: transparent transparent transparent $intro-tooltip-color;
  }
}

.introjs-dontShowAgain,
.introjs-dontShowAgain input,
.introjs-dontShowAgain label {
  background-color: transparent !important;
  color: white;
}
