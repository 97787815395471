.main-spinner-wrapper {
  position: absolute;
  top: calc(50vh - 4rem);
  left: calc(50% - 4rem);
  //height: calc(100vh - $navbar-height - 4rem);
  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(#815fff, 0.3);
    border-top-color: #815fff;//#979fd0;
    animation: 1.5s spin infinite linear;
  }
}


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}