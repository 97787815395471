/*This file ios made for all segments of the application where
we never want the main page to have an overflow and we want everything
to fall and flow in one comntainer*/

//layout

//--main
#mmenu_screen > .row {
  height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);
}
#mmenu_screen_chart > .row {
  height: calc(100vh - (58px + $chart-navbar-height));
  max-height: calc(100vh - 93px);
}

.fs-height{
  height: calc(100vh - $navbar-height);
  max-height: calc(100vh - $navbar-height);
}

.flex-fill {
  flex: 1 1 auto;
}

#vertical-wrapper {
  width: 75%;
  height: calc(100vh - 55px);
  position: relative;
}
//wrapper for full width for when the horizontal wrapper isnt apparent
#full-width-vertical-wrapper {
  width: 100%;
  height: calc(100vh - 93px);
  position: relative;
}

#horizontal-wrapper {
  width: 25%;
  height: 100%;
  position: relative;
  background-color: $themebackground;
}

#main-panel {
  height: 75%;
  background-color: $themebackground;
}

#sub-panel {
  height: 25%;
  width: 100%;
  position: relative;
  background-color: $themebackground;
}
#sub-panel-container {
  width: 100%;
  position: relative;
}

//--nav
.fs-flex-nav {
  transition: height $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration
      $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  //overflow-y: hidden;
  white-space: nowrap;
  overflow: none;
  background: $themebackground;
  height:$nav-tabs-height;
}

.fs-flex-nav div {
  margin: 0;
  display: inline-block;
  text-align: center;
  justify-content: center;
  border-right: 1px solid $border-color;
}

.nav-link.active {
  background: lighten($card-bg, 10%);
}

//--dragbars
#dragbar-vertical {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  cursor: row-resize;
  background: $border-color;
  transition: background 0.3s ease-in-out 0s;
  position: relative;
}

#dragbar-horizontal {
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  cursor: col-resize;
  background: $border-color;
  transition: background 0.3s ease-in-out 0s;
}

#dragbar-horizontal:hover,
#dragbar-vertical:hover,
body.resizing {
  background: $widget;
  transition: background 0.3s ease-in-out 0.3s;
}

//components
.border-bottom {
  border-bottom: $border-width solid $border-color;
  width: 100%;
}
.border-right {
  border-right: $border-width solid $border-color;
  height: 100%;
}
.border-left {
  border-left: $border-width solid $border-color;
  height: 100%;
}

.full-height {
  height: 100%;
}

.border {
  border: $border-width solid $border-color;
}

.active-tile {
  border: $border-width solid $widget;
}
.tile {
  cursor: pointer;
}
.tile:hover {
  background: $themebackground;
}

.sub-panel-table-container {
  width: 100%;
}

.fs-flex-modal {
  height: 70vh;
}

// #nav-tabs{
//   overflow: hidden;
// }

//spinner
.fs-flex-spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  //height: calc(100vh - #{$navbar-height} - 5.5rem);
  //position: fixed;
  width: 100%;
  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba($widget, 0.3);
    border-top-color: $widget;
    animation: 1.5s spin infinite linear;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

//scroll

#heatmap-container,
#horizontal-wrapper,
.sub-panel-table-container,
.fs-flex-nav,
.sub-panel-content,
.scroll {
  overflow-y: auto;
  overflow-x: none;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $border-color;
    box-shadow: inset 0 0 6px $border-color;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    -webkit-box-shadow: inset 0 0 6px lighten($input-bg, 15%);
  }
  &::-webkit-scrollbar-corner {
    background: $border-color;
  }
}

.testt {
  margin: 0;
  min-height: 100%;
  background: #edc;
  display: flex;
  flex-direction: column;
}
#content1 {
  flex: 1; /* since it is the only one getting a flex attitude, it will fill up all space avalaible*/
  background: yellow;
}
