.settings {
  overflow: auto;
}
.settings-options {
  width: 50%;
  max-width: 40rem;
  display: flex;
  gap: 4.5rem;
  border-bottom: 1px solid #3a3e45;
  li {
    list-style-type: none;
    margin: 0 0 0.2rem 0;
    font-size: 1.2rem;
    cursor: pointer;
  }
  .active {
    border-bottom: 1px solid $info;
    margin-bottom: 0;
  }
}
.settings-content {
  width: 50%;
  max-width: 40rem;
}
.load-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: theme-color(success);
  width: 10%;
  padding: 0.5rem;
  border-radius: 5px;
}
.plan-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.payment-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.plan-card,
.payment-card {
  background: $light-card-bg;
}
.plan-card {
  width: 100%;
  gap: 1rem;
  border-radius: 5px;
  p {
    font-size: 1.2rem;
  }
  .plan-features {
    display: flex;
    align-self: flex-start;
    gap: 1rem;
    p {
      font-size: 0.9rem;
    }
  }
  .plan-link {
    color: $info;
    font-size: 0.8rem;
  }
}
.payment-card {
  padding: 1.5rem;
}

@media (max-width: 768px) {
  .settings-options {
    width: 75%;
  }
  .settings-content {
    width: 75%;
  }
}
@media (max-width: 500px) {
  .settings {
    align-items: center;
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .settings-options {
    width: 100%;
  }
  .settings-content {
    width: 100%;
  }
}
