/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
  background: $content-bg;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;

  &.full-page-wrapper {
    min-height: 100vh;
    width: 100%;
    padding-top: 0;

    .main-panel {
      padding-top: 0;

      .content-wrapper {
        padding: 0;

        > div {
          height: 100%;
        }
      }
    }
  }

  @media (max-width:992px) {
    width: 100%;
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: 100%;
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  transition: padding $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  background: $content-bg;
  padding: 3.5rem;//$content-padding;
  width: 100%;
  @include flex-grow(1);
}
@media (max-width: 991px) {
  .content-wrapper{
      padding: 0.5rem;
  }
}
.container-scroller {
  overflow: hidden;
  display: flex;
  position: relative;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-family: $type1;
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}
.page-title {
  color: $body-color;
  font-size: 1.125rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: .9375rem;
      line-height: 36px;
    }
  }
}
.sticky {
	position: fixed;
  z-index:100;
	top: $navbar-height;
  background-color: $themebackground;
  width: 100%;
}
.sticky-top {
	position: fixed;
  z-index:top;
	top: $navbar-height;
  background-color: $themebackground;
  width: 100%;
}
.sticky-child  {
	position: fixed;
  z-index:100000000;
	top: $navbar-height + $nav-tabs-height;
  background-color: $themebackground;
  width: 100%;
}

.opacity-0{
  opacity: 0;
}
//! [spacing]
.col-m{
  margin: 15px;
}
.col-mt{
  margin-top: 15px;
}
.col-mr{
  margin-right: 15px;
}
.col-mb{
  margin-bottom: 15px;
}
.col-ml{
  margin-left: 15px;
}
.col-mv{
  margin-top: 15px;
  margin-bottom: 15px;
}
.col-mh{
  margin-left: 15px;
  margin-right: 15px;
}
.col-p{
  padding: 15px;
}
.col-pt{
  padding-top: 15px;
}
.col-pr{
  padding-right: 15px;
}
.col-pb{
  padding-bottom: 15px;
}
.col-pl{
  padding-left: 15px;
}
.col-pv{
  padding-top: 15px;
  padding-bottom: 15px;
}
.col-ph{
  padding-left: 15px;
  padding-right: 15px;
}
.pm-0{
  margin: 0;
  padding: 0;
}
//! [end spacing]
.dropdown-menu {
  max-height: 400px;
  overflow-y: scroll;
}

.tradingview-container{
  height:610px;
}

@keyframes fadeOut {
  0% {
     opacity: 1;
  }
  84% {
     opacity: 1;
  }
  100% {
     opacity: 0;
  }
}
#video{
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-animation: fadeOut 8s;
  animation: fadeOut 8s;
  opacity: 0;
}
$sizes : 25vh, 50vh, 75vh, 100vh;
@each $size in $sizes{
  .h-#{$size}{
    height: $size;
  }
}

.pointer{
  cursor: pointer;
}

.abs.top.right{
  position: absolute;
  top: 0;
  right: 0;
}



@import "bourbon";

$color--green: map-get($map: $theme-colors, $key: success);
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $color--green;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $color--green;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px $color--green;
  }
}


.logo-hover {
    text-decoration: none !important;
}


span.grippy {
  transform: rotate(90deg);
  display: inline-block;
  line-height: 5px;
  vertical-align: middle;
  margin-right: .3em;
}

span.grippy::before,
span.grippy::after {
  content: '....';
  display: block;
  text-align: center;
  font-size: 12px;
  font-family: sans-serif;
  letter-spacing: 2px;
  color: #cccccc;
  text-shadow: 1px 0 1px black;
}


.draggable {
  top: auto !important;
  left: auto !important;
}


.no-pointer-btn {
  cursor: default !important;
}

