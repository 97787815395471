@tailwind base;
@tailwind components;
@tailwind utilities;
/* === Plugin styles === */
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~nouislider/distribute/nouislider.min.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import '~react-bootstrap-typeahead/css/Typeahead-bs4.css';
@import '~react-datepicker/dist/react-datepicker.css';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import '../assets/styles/variables';
@import '~compass-mixins/lib/compass';
@import '~compass-mixins/lib/animate';
@import '~bootstrap/scss/bootstrap';
@import '../assets/styles/fonts';
@import '../assets/styles/functions';
@import '../assets/styles/intro';

/* === Icon fonts === */
@import '~ti-icons/css/themify-icons.css';
@import '~@mdi/font/scss/materialdesignicons';
@import '~font-awesome/scss/font-awesome';
@import '~flag-icon-css/sass/flag-icon';
@import '~simple-line-icons/scss/simple-line-icons';
@import '~simple-line-icons/scss/simple-line-icons';
@import '~typicons.font/src/font/typicons.css';

/* === Template mixins === */
@import '../assets/styles/mixins/animation';
@import '../assets/styles/mixins/badges';
@import '../assets/styles/mixins/buttons';
@import '../assets/styles/mixins/misc';
@import '../assets/styles/mixins/color-functions';
@import '../assets/styles/mixins/cards';
@import '../assets/styles/mixins/blockqoute';
@import '../assets/styles/mixins/popovers';
@import '../assets/styles/mixins/tooltips';
@import '../assets/styles/mixins/no-ui-slider';

/* === Core Styles === */
@import '../assets/styles/typography';
@import '../assets/styles/reset';
@import '../assets/styles/responsive';
@import '../assets/styles/misc';
@import '../assets/styles/utilities';
@import '../assets/styles/spinner';
/* === Components === */
@import '../assets/styles/components/checkbox-radio';
@import '../assets/styles/components/forms';
@import '../assets/styles/components/tables';
@import '../assets/styles/components/buttons';
@import '../assets/styles/components/breadcrumbs';
@import '../assets/styles/components/badges';
@import '../assets/styles/components/cards';
@import '../assets/styles/components/modals';
@import '../assets/styles/components/tooltips';
@import '../assets/styles/components/popovers';
@import '../assets/styles/components/lists';
@import '../assets/styles/components/bootstrap-progress';
@import '../assets/styles/components/bootstrap-alerts';
@import '../assets/styles/components/tabs';
@import '../assets/styles/components/dropdowns';
@import '../assets/styles/components/pagination';
@import '../assets/styles/components/spinner';
@import '../assets/styles/components/tickets';
@import '../assets/styles/components/fullscreen-flex';
@import '../assets/styles/components/amcharts';
@import '../assets/styles/components/relative-value';
@import '../assets/styles/animations';
@import '../assets//styles/components/settings/settings';
@import '../assets/styles/components/top-dispersions';
@import '../assets/styles/components/pricing/pricing';
@import '../assets/styles/components/containers';
@import '../assets/styles/components/pair-info';
@import '../assets/styles/components/news-tile';
@import '../assets/styles/components/chart-navbar';
@import '../assets/styles/components/options';
@import '../assets/styles/components/preview';

/* === Plugin Overrides === */
@import '../assets/styles/components/plugin-overrides/no-ui-slider';
@import '../assets/styles/components/plugin-overrides/date-picker';
@import '../assets/styles/components/plugin-overrides/react-bootstrap-table';
@import '../assets/styles/components/plugin-overrides/sweet-alert';
@import '../assets/styles/components/plugin-overrides/full-calendar';
@import '../assets/styles/components/plugin-overrides/jquery-jvectormap';

/* === Layout === */
@import '../assets/styles/navbar';
@import '../assets/styles/footer';
