//animation for expand/collaspe
#expand-contract {
  margin-top: -100%;
  transition: all 1s;
}

#expand-contract.expanded {
  margin-top: 0;
}

@each $color, $value in $theme-colors{
.text-#{$color}-hover:hover{
  color: $value !important;
}
}