//full screen when there is 1 additional nav
.relative-value-height {
  height: calc(100vh - ($navbar-height + 50px));
  max-height: calc(100vh - ($navbar-height + 50px));
}
//full screen when there is 2 additional nav
.relative-value-pair-info-height {
  height: calc(100vh - ($navbar-height + $chart-navbar-height + 45px));
  max-height: calc(100vh - ($navbar-height + $chart-navbar-height + 45px));
}
.relative-value-chart-offset {
  height: $nav-tabs-height + $chart-navbar-height;
}
.relative-value-options-chart-offset {
  height: $nav-tabs-height;
}
.options-optimizer-walkthrough-card {
  margin-top: 100px;
}
.navtabs-offset {
  margin-top: $nav-tabs-height;
}
.graph-wrapper {
  height: 50px;
}
