@use 'sass:map';
.option-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 4px;
  //padding: 15px 20px;
  //margin-bottom: 15px;
  //cursor: pointer;
  text-decoration: none;
  color: $body-color;
  transition-duration: 0.3s;
  transition-property: 'background';

  .option-details {
    margin-right: auto;

    .wrapper {
      display: flex;
      align-items: center;

      h5 {
        margin-bottom: 0;
      }

      span {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }

        i {
          margin-right: 5px;
          font-size: 15px;
        }
      }
    }

    h5 {
      margin-bottom: 8px;
    }

    .badge {
      margin-left: 15px;
    }

    .assignee-avatar {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin-left: 10px;
    }
  }

  .ticket-float {
    display: inline-flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }

    span {
      @extend .ellipsis;
    }

    img,
    .category-icon {
      margin-right: 10px;
    }

    .category-icon {
      font-size: 22px;
      color: $text-muted;
    }
  }

  &:hover {
    text-decoration: none;
    color: inherit;
    background: lighten($card-bg, 5%);
  }
  &.active {
    text-decoration: none;
    color: inherit;
    background: lighten($card-bg, 10%);
    border-color: map.get($theme-colors, 'info');
  }
  .fa-trash {
    color: $text-muted;
    &:hover {
      color: map.get($theme-colors, 'danger');
    }
  }
  .fa-check-circle-o {
    color: $text-muted;
    &:hover {
      color: map.get($theme-colors, 'success');
    }
  }
  &.light{
       background: lighten($card-bg, 5%); 
  }
  @media (max-width: 567px) {
    padding: 15px 0;
  }
}

.greek-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border: 1px solid $border-color;
  border-radius: 4px;  text-decoration: none;
  background-color:lighten($card-bg, 7%);

}
