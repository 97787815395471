/* Tables */

.table {
  margin-bottom: 0;
  thead {
    th {
      color: $text-muted; 
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type1;
      font-weight: $font-weight-medium;
      i{
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    color: $white;
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless{
    border: none;
    tr,td,th{
      border: none;
    }
  }
}

.ticker-modal-table{
  thead{
    :hover{
      background-color: transparent!important;
    }
    th{
      color:white;
      font-size: 10px;
    }
  }
  tbody{
  overflow-y: scroll;
    th{
      color:white;
    }
  }
}