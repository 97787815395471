.news-tile{
    .card{
        &:hover{
            background: lighten($card-bg, 10%); 
        }
        height:calc(700px - 43px);
    }
}

.news-img-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    height: 200px;
    min-height: 200px;
    position: relative;
    .news-img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        transform: scale(1);
        z-index: 1;
        border-radius: 0px;
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0);
        transition-duration: 0.3s;
        transition-timing-function: "ease";
        transition-property: "transform", "border-radius", "box-shadow";
        height: 200px;
    }
    &:hover {
        .news-img {
        transform: scale(1.3);
        border-radius: 4px;
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.58);
        overflow: hidden;
        opacity: 0.5;
        }
        .news-img-icon{
            opacity: 1;
        }
    }
    .news-img-icon{
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        z-index: 100;
        opacity: 0;
        transition-duration: 0.3s;
        transition-timing-function: "ease";
        transition-property: "opacity";

    }
}