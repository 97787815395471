.modal{
  z-index: 1000000000000;
  .modal-dialog{
    .modal-content{
      // background-color: $light-card-bg;
      @for $i from 1 through 10
      {

        .modal-body-h#{$i*100}{
          min-height: $i*100px;
        }
      }

    }
  }
  &.right {
    .modal-header {
      height: $navbar-height;
    }
    .modal-dialog {
      transform: translateX(100%);
      transition: transform 0.3s ease-out;
      position: fixed;
      top: 0;
      right: -400px;
      bottom: 0;
      width: 400px;
      transition: right 0.3s ease-out;
      margin: 0;
      @media (max-width: 480px) {
        width: 100%;
      }
    }
    &.show .modal-dialog {
      transform: translateX(0);
      right: 0;
    }

    .modal-body{
      height: 100vh;
    }
  }
  &.left {
    .modal-dialog {
      transform: translateX(-100%);
      transition: transform 0.3s ease-out;
      position: fixed;
      top: 0;
      left: -400px;
      bottom: 0;
      width: 400px;
      transition: left 0.3s ease-out;
      margin: 0;
      @media (max-width: 480px) {
        width: 100%;
      }
    }

    &.show .modal-dialog {
      left: 0;
      transform: translateX(0);
    }
  }

  div[data-rbd-droppable-id]{
    height: calc(100vh - $navbar-height);
    overflow-y: auto;
  }
}

