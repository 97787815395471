.spinner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
  position: fixed;
  width:100%;
  top:calc(50% - 4rem);
  left:0rem;
  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba($widget, 0.3);
    border-top-color: $widget;
    animation: 1.5s spin infinite linear;
  }
}


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}