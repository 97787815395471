@use 'sass:map';
.pair-info-body{
    height: 700px;

}
.pair-info-error{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pair-info-chart{
    height:calc(700px - 41px);
}
