.am5stock {
  .am5stock-link {
    color: $white;
    &:hover {
      background: $table-inverse-bg;
      text-decoration: none;
    }
  }

  &.am5stock-control {
    border: none;
    color: $white;
    border-radius: 0;
    cursor: pointer;
  }
}
.chartcontrols {
  min-height: 35px;
  max-height: 35px;
  &div {
    display: inline-block !important;
  }
  &input {
    @extend .form-control;
  }
}

@media (max-width: 992px) {
  .am5stock-control-label {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .am5stock-link {
    display: none !important;
  }
}
@media (max-width: 425px) {
  .chartcontrols {
    display: none !important;
  }
}
.flatpickr-input {
  @extend .form-control;
}
.am5-modal-button {
  @extend .btn;
  @extend .btn-primary;
}

.charting-module-chart-container {
  height: calc(100vh - 100px);
  position: relative;
}