@use 'sass:map';
.card-tile {
  @extend .card-container;
  &:hover {
    text-decoration: none;
    color: inherit;
    background: lighten($card-bg, 5%);
  }
  &.active {
    text-decoration: none;
    color: inherit;
    background: lighten($card-bg, 10%);
    border-color: map.get($theme-colors, 'info');
  }
  &.active-light{

    text-decoration: none;
    color: inherit;
    background: lighten($card-bg, 5%);
  }
  .fa-trash {
    color: $text-muted;
    &:hover {
      color: map.get($theme-colors, 'danger');
    }
  }
  .fa-check-circle-o {
    color: $text-muted;
    &:hover {
      color: map.get($theme-colors, 'success');
    }
  }
  &.light{
       background: lighten($card-bg, 5%); 
  }
  &.ghost{
    color:$text-muted;
    opacity: 0.5;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    border-style: dashed;
    border: 1px dashed $text-muted;
  }
}
